import Styles from "./Card.css";
import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

function Card({ imagen, title, description }) {
  const [show, setShown] = useState(false);
  const [scaleValue, setScaleValue] = useState(1);

  // Responsive scaling effect
  useEffect(() => {
    const updateScaleValue = () => {
      if (window.innerWidth > 768) {
        setScaleValue(1); // Normal scale for large screens
      } else if (window.innerWidth > 600) {
        setScaleValue(0.9); // Slightly smaller scale for medium screens
      } else {
        setScaleValue(0.8); // Smaller scale for small screens
      }
    };

    updateScaleValue(); // Set initial value
    window.addEventListener("resize", updateScaleValue);
    return () => window.removeEventListener("resize", updateScaleValue);
  }, []);

  // Spring animation
  const props3 = useSpring({
    opacity: 1,
    transform: show
      ? `scale(${scaleValue * 1.03})` // Slightly larger scale on hover
      : `scale(${scaleValue})`, // Base scale determined by screen size
    boxShadow: show
      ? `0px ${scaleValue * 20}px ${scaleValue * 25}px rgb(0 0 0 / 25%)`
      : `0px ${scaleValue * 2}px ${scaleValue * 10}px rgb(0 0 0 / 8%)`,
  });

  return (
    <animated.div
      className={Styles.card}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <img src={imagen} alt={title} />
      <div className="card-details">
        <div className="title">{title}</div>
        <p>{description}</p>
      </div>
    </animated.div>
  );
}

export default Card;










// import Styles from"./Card.css";
// import React, { useState } from "react";
// import { useSpring, animated } from '@react-spring/web';

// function Card({ imagen,title,description }) {
//   const [show, setShown] = useState(false);
  
//   const props3 = useSpring({
//     opacity: 1,
//     transform: show ? "scale(1.03)" : "scale(1)",
//     boxShadow: show
//       ? "0 20px 25px rgb(0 0 0 / 25%)"
//       : "0 2px 10px rgb(0 0 0 / 8%)"
//   });
  
//   return (
//     <animated.div
//       className={Styles.card}
//       style={props3}
//       onMouseEnter={() => setShown(true)}
//       onMouseLeave={() => setShown(false)}
//     >
//       <img src={imagen} alt="" />
//       <h2>{title}</h2>
      
//       <p>
//         {description}
//       </p>
      
//     </animated.div>
//   );
// }

// export default Card;