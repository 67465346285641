import React, { useEffect } from "react";
import { ThemeContextProvider } from "../../themecontext";
import Footer from "../footer/footer";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ThemeContextProvider>
      <div>
        <div className="privacy-policy">
          <div className="container">
            <h1 className="text-center pb-5">Privacy Policy</h1>
            <p>
              <strong>Effective Date:</strong> Dec 4, 2024<br></br>
              <strong>Last Updated:</strong> Dec 4, 2024
            </p>
            <p>
            Kombat+ ("we," "our," or "us") is committed to protecting your privacy and ensuring transparency in how your personal information is collected, used, and shared. This Privacy Policy explains our data practices and outlines your rights concerning your data under applicable privacy laws, including the General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other international regulations.
            </p>
            <p>
            By accessing or using Kombat+, you agree to this Privacy Policy. If you do not agree with this policy, discontinue using the platform.
            </p>

            <h2>1. Data We Collect</h2>
            <p>
              We collect information from you in various ways, including
              directly from you, through your use of the platform, and via third
              parties. The types of data collected include:
            </p>
            <h3>1.1 Personal Information</h3>
            <ul>
              <li>
                <strong>Identifiers:</strong> Name, email address, billing and
                shipping address, phone number, date of birth, username, or
                account ID.
              </li>
              <li>
                <strong>Financial Information:</strong> Credit card details,
                payment methods, and transaction history.
              </li>
            </ul>
            <h3>1.2 Technical and Usage Data</h3>
            <ul>
              <li>
                <strong>Device Information:</strong> Device type, operating
                system, browser type, IP address, and unique device identifiers.
              </li>
              <li>
                <strong>Usage Data:</strong> Pages visited, features used, links
                clicked, time spent on the platform, and interaction history.
              </li>
            </ul>
            <h3>1.3 Blockchain Data</h3>
            <p>
              Public wallet addresses, transaction details, and digital asset
              ownership information.
            </p>
            <h3>1.4 Location Data</h3>
            <p>
              Approximate geographic location inferred from your IP address or
              explicitly provided by you.
            </p>
            <h3>1.5 Cookies and Tracking Technologies</h3>
            <p>
              We use cookies, pixels, and similar technologies to track user
              activity, store preferences, and improve your experience. For
              details, see our <a href="/cookie-policy">Cookie Policy</a>.
            </p>

            <h2>2. How We Use Your Information</h2>
            <p>We use the data we collect for the following purposes:</p>
            <h3>2.1 Service Delivery</h3>
            <ul>
              <li>
                To process transactions, manage subscriptions, and provide
                access to platform features.
              </li>
              <li>To fulfill orders for merchandise, tickets, or NFTs.</li>
            </ul>
            <h3>2.2 Personalization and Improvement</h3>
            <ul>
              <li>
                Tailoring content recommendations and ads based on your
                preferences and behavior.
              </li>
              <li>
                Improving platform performance, user experience, and service
                offerings using aggregated data analytics.
              </li>
            </ul>
            <h3>2.3 Marketing and Communications</h3>
            <ul>
              <li>
                Sending newsletters, promotional materials, or event updates
                with your consent.
              </li>
              <li>
                Conducting surveys, contests, or giveaways to engage our
                community.
              </li>
            </ul>
            <h3>2.4 Security and Compliance</h3>
            <ul>
              <li>
                Detecting and preventing fraud, unauthorized access, or
                malicious activity.
              </li>
              <li>
                Ensuring compliance with applicable laws, such as anti-money
                laundering regulations (AML).
              </li>
            </ul>

            <h2>3. Legal Bases for Processing Data (GDPR)</h2>
            <p>
              Under the GDPR, we process your personal data based on the
              following legal grounds:
            </p>
            <ul>
              <li>
                <strong>Consent:</strong> When you explicitly agree to specific
                processing, such as receiving marketing emails.
              </li>
              <li>
                <strong>Contractual Necessity:</strong> When data processing is
                required to fulfill a contract, e.g., delivering tickets or
                merchandise.
              </li>
              <li>
                <strong>Legitimate Interests:</strong> To enhance platform
                security, improve services, and prevent fraud.
              </li>
              <li>
                <strong>Legal Obligations:</strong> When processing is necessary
                to comply with laws, such as tax reporting.
              </li>
            </ul>

            <h2>4. Data Sharing and Disclosure</h2>
            <p>
              We do not sell your personal data. However, we may share your
              information under specific circumstances:
            </p>
            <h3>4.1 With Service Providers</h3>
            <p>We engage third-party vendors for:</p>
            <ul>
              <li>Payment processing (e.g., Stripe, PayPal).</li>
              <li>Cloud storage and hosting services.</li>
              <li>Analytics and marketing tools (e.g., Google Analytics).</li>
            </ul>
            <p>These providers are bound by contracts that require them to safeguard your data.            </p>
            <h3>4.2 For Legal Compliance</h3>
            <p>We may disclose your data:</p>
            <ul>
              <li>
                To comply with legal obligations, such as subpoenas or court
                orders.
              </li>
              <li>
                To enforce our Terms of Service or respond to claims of misuse.
              </li>
            </ul>
            <h3>4.3 Business Transfers</h3>
            <p>
              In case of a merger, acquisition, or sale of assets, your data may
              be transferred to the acquiring entity.
            </p>
            <h3>4.4 With Your Consent</h3>
            <p>
              We may share your data with third parties for specific purposes if
              you explicitly agree.
            </p>

            <h2>5. Data Retention</h2>
            <p>We retain personal data for as long as necessary to:</p>
            <ul>
              <li>Provide the services you requested.</li>
              <li>Fulfill legal, regulatory, or contractual obligations.</li>
              <li>Maintain security and fraud prevention measures.</li>
            </ul>
            <p>
              Data that is no longer needed will be anonymized or securely
              deleted.
            </p>

            <h2>6. Your Rights</h2>
            <p>You have rights under applicable privacy laws, including:</p>
            <h3>6.1 Under GDPR</h3>
            <ul>
              <li>
                <strong>Right of Access:</strong> Request copies of your data.
              </li>
              <li>
                <strong>Right to Rectification:</strong> Correct inaccurate or
                incomplete data.
              </li>
              <li>
                <strong>Right to Erasure:</strong> Request deletion of your data
                under specific circumstances.
              </li>
              <li>
                <strong>Right to Data Portability:</strong> Receive your data in
                a structured, machine-readable format.
              </li>
              <li>
                <strong>Right to Object:</strong> Restrict or object to specific
                data processing activities.
              </li>
            </ul>
            <h3>6.2 Under CCPA</h3>
            <ul>
              <li>
                <strong>Know:</strong> What personal data we collect and how it
                is used.
              </li>
              <li>
                <strong>Delete:</strong> Request deletion of their personal
                data.
              </li>
              <li>
                <strong>Opt-Out:</strong> Prevent the sale of personal data (we
                do not sell personal data).
              </li>
            </ul>

            <h2>7. Security Measures</h2>
            <p>We implement robust security protocols, including:</p>
            <ul>
              <li>
                Encryption of sensitive data during transmission and storage.
              </li>
              <li>Multi-factor authentication for account access.</li>
              <li>Regular vulnerability assessments and compliance audits.</li>
            </ul>
            <p>
              However, no system is completely secure, and users are advised to
              safeguard their login credentials.
            </p>

            <h2>8. Cross-Border Data Transfers</h2>
            <p>
              We operate globally and may transfer data outside your country of
              residence. When transferring data, we ensure adequate protections,
              including:
            </p>
            <ul>
              <li>
                EU Standard Contractual Clauses for transfers outside the EEA.
              </li>
              <li>Certifications such as Privacy Shield (if applicable).</li>
            </ul>

            <h2>9. Cookies and Tracking</h2>
            <p>Cookies are used for:</p>
            <ul>
              <li>
                <strong>Essential Functions:</strong> Ensuring platform
                functionality.
              </li>
              <li>
                <strong>Analytics:</strong> Understanding user behavior to
                improve services.
              </li>
              <li>
                <strong>Advertising:</strong> Delivering personalized ads.
              </li>
            </ul>
            <p>
              You can manage your cookie preferences via your browser settings.
            </p>

            <h2>10. Children’s Privacy</h2>
            <p>
              Our platform is not intended for children under the age of 18. We
              do not knowingly collect data from children. If you believe we
              have collected such data, contact us at{" "}
              <a href="mailto:contact@kombatplus.com">contact@kombatplus.com</a>
              .
            </p>

            <h2>11. Updates to This Policy</h2>
            <p>
              We may update this Privacy Policy periodically. Significant
              changes will be communicated through email or on the platform.
              Continued use of the platform indicates your acceptance of these
              updates.
            </p>

            <h2>12. Contact Us</h2>
            <p>For inquiries or to exercise your data rights, contact us at:</p>
            <p>
              <strong>Email:</strong>
             
              <br></br>
              <strong>Phone:</strong>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </ThemeContextProvider>
  );
};

export default PrivacyPolicy;
