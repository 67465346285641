import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

function CommonCarousel({ contents }) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            {contents.map((content, idx) => (
                <Carousel.Item key={idx}>
                    <img
                        className="d-block w-100"
                        src={content.src}
                        alt={`Slide ${idx + 1}`}
                    />
                    <Carousel.Caption>
                        <h1>{content.text}</h1>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default CommonCarousel;
