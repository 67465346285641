import React, { useEffect } from "react";
import { ThemeContextProvider } from "../../themecontext";
import Footer from "../footer/footer";

const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ThemeContextProvider>
      <div className="privacy-policy">
        <div className="container">
          <h1 className="text-center pb-5">Cookie Policy</h1>
          <p>
            <strong>Effective Date:</strong> Dec 4, 2024<br></br>
            <strong>Last Updated:</strong> Dec 4, 2024
          </p>
          <p>
            Kombat+ ("we," "our," or "us") uses cookies and similar tracking
            technologies to enhance your experience, understand user behavior,
            and improve the services we provide. This Cookie Policy explains
            what cookies are, how we use them, and your options for managing
            them.
          </p>
          <p>
            By continuing to use our website or services, you consent to the use
            of cookies as described in this policy.
          </p>

          <h2>1. What Are Cookies?</h2>
          <p>
            Cookies are small text files stored on your device (computer,
            smartphone, or tablet) when you visit a website. These files enable
            websites to recognize your device, store preferences, and track
            activities to improve functionality and personalize content.
          </p>
          <h3>1.1 Types of Cookies</h3>
          <p>We use various types of cookies, including:          </p>
          <ul>
            <li>
              <strong>Session Cookies:</strong> Temporary cookies that expire
              when you close your browser.
            </li>
            <li>
              <strong>Persistent Cookies:</strong> Remain on your device until
              they expire or are deleted.
            </li>
            <li>
              <strong>First-Party Cookies:</strong> Set by our platform to
              enable core functionality.
            </li>
            <li>
              <strong>Third-Party Cookies:</strong> Set by third parties for
              analytics, advertising, and social media integration.
            </li>
          </ul>

          <h2>2. Cookies We Use</h2>
          <h3>2.1 Strictly Necessary Cookies</h3>
          <p>
            These cookies are essential for the operation of our platform. They
            include:
          </p>
          <ul>
            <li>Authentication cookies for logging in.</li>
            <li>Security cookies to prevent fraudulent activities.</li>
            <li>Session cookies for load balancing and system management.</li>
          </ul>
          <p>
            <em>Example:</em> PHPSESSID (Session ID for login authentication).
          </p>

          <h3>2.2 Performance and Analytics Cookies</h3>
          <p>
            These cookies collect information about how visitors use our
            platform, helping us understand usage patterns and improve services.
          </p>
          <ul>
            <li>
              <strong>Purpose:</strong>
              <ul>
                <li>Monitor traffic and user activity.</li>
                <li>Identify and resolve technical issues.</li>
              </ul>
            </li>
            <li>
              <strong>Examples of tools used:</strong>
              <ul>
                <li>
                  <b>Google Analytics:</b> Analyzes visitor behavior and platform
                  performance.
                </li>
                <li><b>Mixpanel:</b> Tracks user interactions and feature usage.</li>
              </ul>
            </li>
          </ul>

          <h3>2.3 Functional Cookies</h3>
          <p>
            These cookies enable enhanced functionality and personalization.
            They may store your preferences, such as language, region, or login
            information.
          </p>
          <ul>
            <li>
              <strong>Purpose:</strong>
              <ul>
                <li>Remember settings to enhance your experience.</li>
                <li>Provide personalized content recommendations.</li>
              </ul>
            </li>
          </ul>

          <h3>2.4 Targeting and Advertising Cookies</h3>
          <p>
            These cookies are used to deliver advertisements that are relevant
            to you. They may track your browsing activity across sites to
            display personalized ads.
          </p>
          <ul>
            <li>
              <strong>Purpose:</strong>
              <ul>
                <li>Tailor ads to your interests.</li>
                <li>Measure ad campaign effectiveness.</li>
              </ul>
            </li>
            <li>
              <strong>Examples:</strong>
              <ul>
                <li><b>Google Ads:</b> Displays tailored advertisements.</li>
                <li>
                  <b>Facebook Pixel:</b> Tracks ad performance and retargets users.
                </li>
              </ul>
            </li>
          </ul>

          <h3>2.5 Social Media Cookies</h3>
          <p>
            These cookies allow you to share content directly to social media
            platforms or log in using your social media credentials.
          </p>
          <ul>
            <li>
              <strong>Purpose:</strong>
              <ul>
                <li>Enable sharing of content.</li>
                <li>Facilitate social media interactions on our platform.</li>
              </ul>
            </li>
            <li>
              <strong>Examples:</strong>
              <ul>
                <li>Buttons for platforms like Twitter,
              Facebook, LinkedIn, or Instagram.</li>
              </ul>
            </li>
          </ul>

          <h2>3. Third-Party Tracking Technologies</h2>
          <p>
            We may allow third-party service providers to place cookies or
            similar tracking technologies on your device to assist in:
          </p>
          <ul>
            <li>Ad targeting and delivery.</li>
            <li>Measuring performance of marketing campaigns.</li>
            <li>Analyzing platform usage.</li>
          </ul>
              <p>These third parties may independently collect data about you and your activities online.              </p>
          <h2>4. How We Use Cookies</h2>
          <p>Cookies help us:</p>
          <ul>
            <li>Improve website functionality and user experience.</li>
            <li>Personalize content and recommend relevant services.</li>
            <li>Monitor and optimize performance.</li>
            <li>Ensure security and fraud prevention.</li>
            <li>Deliver relevant ads and promotional offers.</li>
          </ul>

          <h2>5. Managing Cookies</h2>
          <p>You have the right to manage your cookie preferences and can do so in the following ways:</p>
          <h3>5.1 Browser Settings</h3>
          <p>
            Most web browsers allow you to manage or delete cookies through
            their settings. You can typically:
          </p>
          <ul>
            <li>Block or delete all cookies.</li>
            <li>Allow cookies only from specific websites.</li>
            <li>Enable notifications before accepting cookies.</li>
          </ul>
          <p>
            <strong>Note:</strong> Blocking essential cookies may impact your
            ability to use certain features of our platform.
          </p>

          <h3>5.2 Opt-Out Tools</h3>
          <p>
            We comply with industry standards by offering opt-out tools for
            targeted advertising. Examples include:
          </p>
          <ul>
            <li>
              
                <a href="https://adssettings.google.com/" target="_blank">Google Ads Settings</a>
              
            </li>
            <li>
             
                Network Advertising Initiative
              
            </li>
            <li>
             
            <a href="https://www.youronlinechoices.com/" target="_blank">Your Online Choices</a>
              
            </li>
          </ul>

          <h3>5.3 Cookie Management Tool</h3>
          <p>
            We provide a Cookie Management Tool on our platform, allowing you to
            adjust your preferences for different types of cookies.
          </p>

          <h2>6. Your Rights and Choices</h2>
          <h3>6.1 GDPR Compliance</h3>
          <p>If you are in the EU/EEA, you have the right to:
          </p>
          <ul>
            <li>Provide or withdraw consent for cookies.</li>
            <li>Access information about the cookies we use.</li>
            <li>Request deletion of personal data stored through cookies.</li>
          </ul>

          <h3>6.2 CCPA Compliance</h3>
          <p>If you are a California resident, you have the right to:
          </p>
          <ul>
            <li>Know what personal data is collected via cookies.</li>
            <li>
              Opt out of the "sale" of your data (note: we do not sell personal
              data).
            </li>
          </ul>

          <h2>7. Updates to This Cookie Policy</h2>
          <p>
            We may update this policy periodically to reflect changes in
            technology, legal requirements, or our practices. Significant
            updates will be communicated to you via email or notifications on
            our platform.
          </p>

          <h2>8. Contact Us</h2>
          <p>
            For questions, concerns, or to exercise your rights regarding this
            Cookie Policy, contact us:
          </p>
          <p>
            <strong>Email:</strong>
            <br></br>
            <strong>Mail:</strong>
          </p>
        </div>
      </div>
      <Footer />
    </ThemeContextProvider>
  );
};

export default CookiePolicy;
