import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { MuiTelInput } from "mui-tel-input";
import axios from 'axios'
import { apiEndPoint, headers } from "../../utils/constants";
import SnackbarUI from '../common/snackbar';
import { ThemeContextProvider } from "../../themecontext";
import Footer from "../footer/footer";
import "./becomeanAffiliate.scss";
import becomeIcon1 from "../../assets/become-icon1.svg";
import becomeIcon2 from "../../assets/become-icon2.svg";
import becomeIcon3 from "../../assets/become-icon3.svg";
import { Helmet } from 'react-helmet-async';


const BecomeanAffiliate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const initialState = {
    name: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [open, setOpenSnack] = useState(false);
  const [content, setContent] = useState('Form Submitted Successfully');
  const [severity, setSeverity] = useState('success')

  const handleForm = useCallback((event) => {

    if (event.target) {
      const { name, value, type, checked } = event.target;
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } else if (event) {
      setFormData((prev) => ({
        ...prev,
        phoneNumber: event
      }))
      setErrors((prev) => ({ ...prev, phoneNumber: "" }));
    }
  }, []);

  const validateFormData = (data) => {
    let errors = {};
    if (data.name == "") {
      errors.name = "Required";
    }
    // if (data.companyName == "") {
    //   errors.companyName = "Required";
    // }
    if (data.email == "") {
      errors.email = "Required";
    }
   
    if (data.phoneNumber == "") {
      errors.phoneNumber = "Required"
    }
    return errors
  };

  const saveDraftForm = (e) => {
    e.preventDefault();
    console.log(formData, "formDataformData");

    const validateErrors = validateFormData(formData);
    setErrors(validateErrors)
    if(Object.keys(validateErrors).length === 0){
      axios.post(`${apiEndPoint}/affiliate`, formData, headers)
        .then((response) => {
          if (response.status == 201) {
            setFormSubmitted(true)
            setOpenSnack(true)
          }
        })
        .catch((e) => {
          setOpenSnack(true)
          setContent("Something Went wrong !")
          setSeverity("error")
      })
        // .catch((e) => {
        //   setFormSubmitted(true)//remove this after getting exact API calls(Keeping this just for testing purpose)
        // })
    }
  };
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      companyName: "",
      message: "",
    });
    setErrors({});
    setFormSubmitted(false);
  };

  return (
    <>
    <Helmet>
      <title>Get Paid to Promote What You Love | Kombatplus Affiliate Program</title>
      <meta property="og:title" content="Get Paid to Promote What You Love | Kombatplus Affiliate Program" />
      <meta name="description" content="Join the Kombatplus Affiliate Program and start earning commissions by promoting live events, fight gear, and subscriptions. Share custom referral links with your followers and get paid for every ticket, merchandise, or subscription sold. Explore our competitive commission structure and grow your earnings today." />
      <meta name="keywords" content="affiliate marketing, earn commissions, promote events, combat sports affiliate, referral links, earn by promoting, Kombatplus affiliate, ticket sales affiliate, merchandise affiliate, subscriptions affiliate, sports promotions, affiliate commissions, Kombatplus rewards, affiliate dashboard" />
    </Helmet>
    <ThemeContextProvider>
      <div className="becomean-affiliate">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-8">
              <h1>Get Paid to Promote What You Love</h1>
              <p>
                Become a{" "}
                <span className="logo-text">
                  <span>KO</span>MBAT<span className="plus">+</span>
                </span>{" "}
                affiliate and start earning commissions by promoting fight
                events, exclusive content, and merchandise. Share custom
                referral links with your followers and get paid for every
                ticket, merch item, or subscription sold. Learn more about our
                competitive commission structure!
              </p>
              <div className="options p-3 p-md-5">
                <div className="row text-center justify-content-center">
                  <div className="col-6 col-md-4">
                    <img src={becomeIcon1} className="pb-3"></img>
                    <h5>Promote Events & Merch</h5>
                    <p>Share referral links for live events, fight gear, and subscriptions, earning commissions on every sale</p>
                  </div>
                  <div className="col-6 col-md-4">
                    <img src={becomeIcon2} className="pb-3"></img>
                    <h5>Affiliate Dashboard</h5>
                    <p>Track clicks, conversions, and commission earnings in real time</p>
                  </div>
                  <div className="col-md-4">
                    <img src={becomeIcon3} className="pb-3"></img>
                    <h5>Payouts</h5>
                    <p> Receive payments for successful referrals directly to your account, with transparent reporting on performance</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-box p-3 h-100">
                {/* Form comes */}
                {!formSubmitted ? <form>
                  <div className="pb-3">
                    <FormControl className="w-100" variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        name="name"
                        placeholder="Name*"
                        value={formData.name}
                        onChange={handleForm}
                      />
                    </FormControl>

                    {errors && errors.name && (
                      <p className="error mb-0 text-start">{errors.name}</p>
                    )}
                  </div>
                  <div className="pb-3">
                    <FormControl className="w-100" variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        name="email"
                        placeholder="Email*"
                        value={formData.email}
                        onChange={handleForm}
                      />
                    </FormControl>
                    {errors && errors.email && (
                      <p className="error mb-0 text-start">{errors.email}</p>
                    )}
                  </div>
                  {/* <div className="pb-3">
                            <FormControl className="w-100" variant="outlined">
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                }}
                                name="phoneNumber"
                                placeholder="Phone Number*"
                                value={formData.phoneNumber}
                                onChange={handleForm}
                              />
                            </FormControl>
                            {errors && errors.phoneNumber && (
                              <p className="error mb-0 text-start">{errors.phoneNumber}</p>
                            )}
                          </div> */}

                  <div className="pb-3">
                    <FormControl className="w-100" variant="outlined">
                      <MuiTelInput
                        defaultCountry="US"
                        value={formData.phoneNumber}
                        onChange={handleForm}
                        placeholder="Phone Number*"
                        name="phoneNumber"
                        error={!!errors.phoneNumber} // Show error outline if there's an error
                      />
                    </FormControl>
                    {errors && errors.phoneNumber && (
                      <p className="error mb-0 text-start">{errors.phoneNumber}</p>
                    )}
                  </div>
                  <div className="pb-3">
                    <FormControl className="w-100" variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        name="companyName"
                        placeholder="Company"
                        value={formData.companyName}
                        onChange={handleForm}
                      />
                    </FormControl>
                  </div>
                  <div className="pb-3">
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      defaultValue="Athlete Bio"
                      className="w-100"
                      name="message"
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleForm}
                    />
                  </div>
                  {/* <div>
                            <div className="d-flex align-items-center pb-3">
                              <Checkbox
                                type="checkbox"
                                name="isChecked"
                                checked={formData.isChecked}
                                onChange={(e) => handleForm(e)}
                              />
                              <label for="checkbox">
                                {" "}
                                Agree with <a href="/terms-of-service">Terms</a> and <a href="/cookie-policy">Privacy policy</a>
                              </label>
                            </div>
                            {errors.isChecked && (
                              <p
                                className="error mb-0 text-start ps-2"
                                style={{ position: "relative", top: "-20px" }}
                              >
                                {errors.isChecked}
                              </p>
                            )}
                          </div> */}
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100 rounded-pill"
                    onClick={saveDraftForm}
                  >
                    Submit
                  </Button>
                </form> : (
                  <div className="thank-you-message h-100 text-center d-flex align-items-center justify-content-center">
                    <div>
                      <h3 className="text-white">Thank You for Submitting the Form!</h3>
                      <p className="text-white">We appreciate your interest and will be in touch soon.</p>
                      <Button
                        variant="primary"
                        className="mt-3 text-white px-5"
                        onClick={resetForm}
                      >
                        Ok
                      </Button>
                    </div>
                 </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </ThemeContextProvider>
    <SnackbarUI open={open} setOpenSnack={setOpenSnack} content={content} severity={severity} />
    </>
  );
};

export default BecomeanAffiliate;
