import React, { useCallback, useEffect, useState } from "react";
import { ThemeContextProvider } from "../../themecontext";
import Footer from "../footer/footer";
import "./AboutUs.scss";
import arun from "../../assets/arun.png";
import darren from "../../assets/darren.png";
import { Helmet } from 'react-helmet-async';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Helmet>
      <title>About Us | KombatPlus – Powering the Next Generation of Combat Sports Media</title>
      <meta property="og:title" content="About Us | KombatPlus – Powering the Next Generation of Combat Sports Media" />
      <meta name="description" content="KombatPlus is revolutionizing combat sports media by empowering promotions, influencers, and content creators with cutting-edge tools to engage fans and monetize content. Our platform offers interactive streaming, real-time fan engagement, and multiple monetization opportunities to fuel the growth of combat sports. Join us in redefining the fan experience." />
      <meta name="keywords" content="KombatPlus, combat sports media, platform as a service, sports promotions, influencers, fan engagement, interactive sports experiences, combat sports content, combat sports platform, AI-driven sports media, monetization tools, sports streaming, pay-per-view, e-commerce for sports, sports content creators, personalized fan experiences" />
    </Helmet>
    <ThemeContextProvider>
      <div className="aboutus">
        <div className="hero-section">
          <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h1>
                  Welcome to
                  <span className="logo-text px-2">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                  – Powering the Next Generation of Combat Sports Media
                </h1>
                <p>
                  <span className="logo-text px-2">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                  is the premier media platform-as-a-service (PaaS) designed
                  exclusively for the combat sports industry. We provide combat
                  sports promotions, influencers, and content creators with the
                  tools they need to reach fans, build brands, and monetize
                  their content like never before. Whether you represent an
                  established promotion or are an emerging influencer,
                  <span className="logo-text px-2">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                  is here to help you engage your audience, expand your reach,
                  and deliver high-quality, interactive combat sports
                  experiences.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <h2>Our Mission</h2>
          <p>
            Our mission is to fuel the growth of combat sports by empowering
            promotions and influencers to connect with fans through innovative,
            cutting-edge media experiences. We aim to create a dynamic and
            engaging platform that celebrates the passion of combat sports and
            provides audiences with the most immersive and interactive viewing
            experience available.
          </p>
          <p>
            At
            <span className="logo-text ps-2">
              <span>KO</span>MBAT<span className="plus">+</span>
            </span>
            , we believe in:
          </p>
          <div className="row gy-4 py-5">
            <div className="col-md-6">
              <div className="features-box p-4 h-100">
                <h4>Empowering Promotions and Influencers</h4>
                <p>
                  Our platform is designed to help combat sports brands and
                  personalities grow, with tools for streaming, on-demand
                  content, live ticketing, interactive fan engagement, and
                  e-commerce options.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="features-box p-4 h-100">
                <h4>Innovative Fan Experiences</h4>
                <p>
                  With real-time interactions, dynamic overlays, polls, and a
                  suite of features tailored for combat sports fans,  <span className="logo-text px-2">
              <span>KO</span>MBAT<span className="plus">+</span>
            </span>
                  transforms traditional viewing into an interactive and
                  immersive experience.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="features-box p-4 h-100">
                <h4>Monetization Opportunities</h4>
                <p>
                  <span className="logo-text pe-2">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                  enables promotions and influencers to leverage multiple
                  revenue streams, from channel subscriptions & pay-per-view
                  events to merchandise sales, advertising, and NFTs, all within
                  a single platform.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="features-box p-4 h-100">
                <h4>AI-Driven Insights and Personalization</h4>
                <p>
                  Leveraging advanced AI capabilities,
                  <span className="logo-text px-2">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                  delivers personalized content and real-time insights,
                  enhancing engagement by understanding fan preferences and
                  improving the overall user experience.
                </p>
              </div>
            </div>
          </div>
          <h2>
            The
            <span className="logo-text px-2">
              <span>KO</span>MBAT<span className="plus">+</span>
            </span>
             Management Team
          </h2>
          <p>
            Our leadership team combines expertise in technology, AI, and combat
            sports, driven by a shared passion for elevating the fan experience
            and supporting creators. Together, they are building a powerful
            platform that meets the evolving needs of the combat sports
            industry.
          </p>
          <div class="d-md-flex align-items-center py-5 text-center text-md-start">
            <img src={arun} class="me-md-5 mb-3 mb-md-0"></img>
            <div>
              <h2 class="profile-name">Arun Parimi</h2>
              <div class="team-designation pb-2">
              Chief Executive Officer & Chief Technology Officer
              </div>
              <p>
              A visionary in sports technology and artificial intelligence, Arun brings extensive experience in AI-driven fan engagement and media innovation. Under Arun’s leadership, <span className="logo-text px-2">
              <span>KO</span>MBAT<span className="plus">+</span>
            </span> integrates advanced AI technologies that analyze fan behavior, optimize content delivery, and personalize user experiences. Arun’s commitment to blending cutting-edge AI with scalable technology ensures that <span className="logo-text px-2">
              <span>KO</span>MBAT<span className="plus">+</span>
            </span> is always at the forefront of interactive and intelligent sports media.
              </p>
            </div>
          </div>
          <div class="d-grid d-md-flex align-items-center py-5 text-center text-md-start">
            <div class="text-md-end order-2 order-md-1">
              <h2 class="profile-name">Darren Owen </h2>
              <div class="team-designation pb-2">Chief Operating Officer</div>
              <p>
              With deep expertise in combat sports and a history of driving growth for prominent promotions, Darren is a trusted leader in the industry. His understanding of the unique needs of combat sports brands and audiences makes him essential to <span className="logo-text px-2">
              <span>KO</span>MBAT<span className="plus">+</span>
            </span>, overseeing operations and ensuring the platform is tailored to serve the industry’s best interests.
              </p>
            </div>
            <div class="ms-md-5 order-1 order-md-2  mb-3 mb-md-0">
              <img src={darren}></img>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </ThemeContextProvider>
    </>
  );
};

export default AboutUs;
