import React, { useCallback, useEffect, useState } from 'react'
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import Button from "react-bootstrap/Button";
import axios from 'axios'
import { apiEndPoint, headers } from "../../utils/constants";
import { ThemeContextProvider } from "../../themecontext";
import Footer from "../footer/footer";
import { Helmet } from 'react-helmet-async'
import SnackbarUI from '../common/snackbar';
import { MuiTelInput } from "mui-tel-input";
import "./SignupForm.scss";


const initialState = {
    name: "",
    email: "",
    phoneNumber: "",
};


const SignupForm = () => {
    const [formData, setFormData] = useState(initialState);
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [errors, setErrors] = useState({});
    const [open, setOpenSnack] = useState(false);
    const [content, setContent] = useState('Form Submitted Successfully');
    const [severity, setSeverity] = useState('success')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleForm = useCallback((event) => {
        console.log(event, "eventeventv");

        if (event.target) {
            const { name, value, type, checked } = event.target;
            setFormData((prev) => ({
                ...prev,
                [name]: type === "checkbox" ? checked : value,
            }));
            setErrors((prev) => ({ ...prev, [name]: "" }));
        } else if (event) {
            setFormData((prev) => ({
                ...prev,
                phoneNumber: event
            }))
            setErrors((prev) => ({ ...prev, phoneNumber: "" }));
        }
    }, []);

    const resetForm = () => {
        setFormData({
            name: "",
            email: "",
            phoneNumber: "",
        });
        setErrors({});
        setFormSubmitted(false);
    };

    const validateFormData = (data) => {
        let errors = {};
        if (data.name == "") {
            errors.name = "Required";
        }
        if (data.email == "") {
            errors.email = "Required";
        }
        if (!data.isChecked) {
            errors.isChecked = "Required";
        }
        if (data.phoneNumber == "") {
            errors.phoneNumber = "Required"
        }
        return errors
    };


    const saveDraftForm = (e) => {
        e.preventDefault();
        const validateErrors = validateFormData(formData);
        setErrors(validateErrors)
        console.log(validateErrors);
        const formdatacopy = { ...formData };

if ('isChecked' in formdatacopy) {
    delete formdatacopy.isChecked;
}
        if (Object.keys(validateErrors).length === 0) {
            axios.post(`${apiEndPoint}/newsletter`, formdatacopy, headers)
                .then((response) => {
                    if (response.status == 201) {
                        setFormSubmitted(true)
                        setOpenSnack(true)
                    }
                })
                .catch((e) => {
                    setOpenSnack(true)
                    setContent("Something Went wrong !")
                    setSeverity("error")
                })
        }
    };

    return (
        <div>

            <ThemeContextProvider>
                <div className="signup">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-8">
                                <h1>Sign up to receive exclusive updates about upcoming events.
                                </h1>
                                
                            </div>
                            <div className="col-md-4">
                                <div className="contact-box p-4">
                                    {!formSubmitted ? <form>
                                        <div className="pb-3">
                                            <FormControl className="w-100" variant="outlined">
                                                <OutlinedInput
                                                    id="outlined-adornment-weight"
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        "aria-label": "weight",
                                                    }}
                                                    name="name"
                                                    placeholder="Name*"
                                                    value={formData.name}
                                                    onChange={handleForm}
                                                />
                                            </FormControl>

                                            {errors && errors.name && (
                                                <p className="error mb-0 text-start">{errors.name}</p>
                                            )}
                                        </div>
                                        <div className="pb-3">
                                            <FormControl className="w-100" variant="outlined">
                                                <OutlinedInput
                                                    id="outlined-adornment-weight"
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        "aria-label": "weight",
                                                    }}
                                                    name="email"
                                                    placeholder="Email*"
                                                    value={formData.email}
                                                    onChange={handleForm}
                                                />
                                            </FormControl>
                                            {errors && errors.email && (
                                                <p className="error mb-0 text-start">{errors.email}</p>
                                            )}
                                        </div>

                                        <div className="pb-3">
                                            <FormControl className="w-100" variant="outlined">
                                                <MuiTelInput
                                                    defaultCountry="US"
                                                    value={formData.phoneNumber}
                                                    onChange={handleForm}
                                                    placeholder="Phone Number*"
                                                    name="phoneNumber"
                                                    error={!!errors.phoneNumber}
                                                />
                                            </FormControl>
                                            {errors && errors.phoneNumber && (
                                                <p className="error mb-0 text-start">{errors.phoneNumber}</p>
                                            )}
                                        </div>

                                        <div>
                                            <div className="d-flex pb-3 align-items-center">
                                                <Checkbox
                                                    type="checkbox"
                                                    name="isChecked"
                                                    checked={formData.isChecked}
                                                    onChange={(e) => handleForm(e)}
                                                />

                                                <label>
                                                    Agree with{' '}
                                                    <span>
                                                        <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">
                                                            Terms
                                                        </a>
                                                    </span>{' '}
                                                    and{' '}
                                                    <span>
                                                        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                                            Privacy and Policy
                                                        </a>
                                                    </span>
                                                </label>
                                            </div>
                                            {errors.isChecked && (
                                                <p
                                                    className="error mb-0 text-start ps-2"
                                                    style={{ position: "relative", top: "-20px" }}
                                                >
                                                    {errors.isChecked}
                                                </p>
                                            )}
                                        </div>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="w-100 rounded-pill"
                                            onClick={saveDraftForm}
                                        >
                                            Submit
                                        </Button>
                                    </form> : (
                                        <div className="thank-you-message text-center">
                                            <h4 className="text-white">Thank You for Submitting the Form!</h4>
                                            <p className="text-white">We appreciate your interest and will be in touch soon.</p>
                                            <Button
                                                variant="primary"
                                                className="mt-3 text-white px-5"
                                                onClick={resetForm}
                                            >
                                                Ok
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footer /> */}
            </ThemeContextProvider>
            <SnackbarUI open={open} setOpenSnack={setOpenSnack} content={content} severity={severity} />
        </div>
    )
}

export default SignupForm;