import React, { useEffect } from "react";
import { ThemeContextProvider } from "../../themecontext";
import Footer from "../footer/footer";

const TermsOfService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ThemeContextProvider>
      <div className="privacy-policy">
        <div className="container">
          <h1 className="text-center pb-5">Terms of Service</h1>
          <p>
            <strong>Effective Date:</strong> Dec 4, 2024<br></br>
            <strong>Last Updated:</strong> Dec 4, 2024
          </p>
          <p>
          Welcome to Kombat+ ("we," "us," or "the Platform"). By accessing or using our services, you agree to these Terms of Service ("Terms"). These Terms are a legally binding agreement between you ("user," "you," or "your") and Kombat+. If you disagree with any part of these Terms, you must discontinue use of the platform.

          </p>

          <h2>1. Overview of Services</h2>
          <p>
          The Platform provides a suite of digital services designed to deliver a seamless combat sports experience to users globally. Key offerings include:

          </p>
          <ul>
            <li>
              <strong>Streaming Services:</strong> Access live and on-demand combat sports content, including exclusive events and promotions, with near real-time interaction capabilities.
            </li>
            <li>
            <strong>Ticketing:</strong> Purchase digital or physical tickets for events via secure payment methods, including fiat and cryptocurrency.</li>
            <li><strong>Merchandising:</strong> Buy official merchandise, limited-edition collectibles, and NFTs related to combat sports.</li>
            <li><strong>Subscriptions:</strong> Subscribe to tiered plans that unlock exclusive features such as ad-free streaming, premium content, and early event access.</li>
            <li><strong>Advertising Solutions:</strong> Benefit from personalized ad experiences tailored to user preferences and powered by AI analytics.</li>
            <li><strong>Web3 Integration:</strong> Use blockchain-enabled features like crypto-based transactions, NFT marketplaces, and token utilities.</li>
            <li><strong>AI-Driven Personalization:</strong> Receive curated content and recommendations based on your viewing and interaction history.

            </li>
          </ul>
          <p>
          Our services are subject to change, expansion, or updates at any time without prior notice. Additional services may have specific terms applicable to them.

          </p>

          <h2>2. Eligibility</h2>
          <h3>2.1 Minimum Age Requirements</h3>
          <p>You must be:</p>
          <ul>
            <li>
              At least 18 years old (or the legal age of majority in your
              jurisdiction).
            </li>
            <li>Authorized to enter into legally binding contracts.</li>
          </ul>
          <p>
            If you are under the age of majority, you may only use the platform
            under the supervision of a parent or legal guardian who agrees to
            these Terms.
          </p>
          <h3>2.2 Compliance with Laws</h3>
          <p>
            Users must ensure their use of the Platform complies with all local,
            state, national, and international laws, including those regulating
            digital commerce, blockchain technologies, and data protection.
          </p>
          <h3>2.3 Account Registration</h3>
          <p>
            To access certain features, you may be required to create an
            account. During registration, you agree to:
          </p>
          <ul>
            <li>Provide accurate, up-to-date information.</li>
            <li>Safeguard your login credentials.</li>
            <li>Notify us of any unauthorized account access immediately.</li>
          </ul>

          <h2>3. User Account Management</h2>
          <h3>3.1 Account Responsibilities</h3>
          <p>
            You are responsible for all activities conducted through your
            account. We reserve the right to suspend or terminate accounts
            involved in prohibited activities.
          </p>
          <h3>3.2 Account Termination</h3>
          <p>We may terminate or suspend your account at our discretion for:</p>
          <ul>
            <li>Violations of these Terms or applicable laws.</li>
            <li>
              Misuse of platform features (e.g., unauthorized streaming or
              fraudulent transactions).
            </li>
            <li>Non-payment or chargebacks.</li>
          </ul>
          <p>
            If your account is terminated, you may lose access to digital
            assets, including tokens or NFTs, without recourse for compensation
            unless required by applicable law.
          </p>

          <h2>4. Payments and Refunds</h2>
          <h3>4.1 Payment Methods</h3>
          <p>We accept payments via:</p>
          <ul>
            <li>Credit/debit cards (Visa, MasterCard, etc.).</li>
            <li>PayPal and other third-party payment gateways.</li>
            <li>Cryptocurrencies supported by our platform.</li>
          </ul>
          <p>
            All payments are processed securely, and transaction fees may apply,
            depending on the payment method.
          </p>
          <h3>4.2 Subscription Plans</h3>
          <ul>
            <li>Subscriptions auto-renew at the end of each billing cycle.</li>
            <li>
              Cancellation requests must be submitted at least 48 hours before
              the next billing cycle to avoid charges.
            </li>
          </ul>
          <h3>4.3 Refund Policy</h3>
          <p>Refunds are subject to the following conditions:</p>
          <ul>
            <li>
              Event cancellations qualify for a full or partial refund based on
              applicable laws.
            </li>
            <li>
              Merchandise refunds are allowed for defective or damaged items
              only, within 14 days of purchase.
            </li>
            <li>
              Subscription refunds for unused portions are granted at our
              discretion.
            </li>
          </ul>
          <p>
            Cryptocurrency payments are non-refundable due to the immutable
            nature of blockchain transactions.
          </p>

          <h2>5. Content and Intellectual Property</h2>
          <h3>5.1 User-Generated Content</h3>
          <p>
            Users may upload or submit content (e.g., comments, reviews, or
            custom NFTs) to the platform. By doing so, you grant us:
          </p>
          <ul>
            <li>
              A non-exclusive, worldwide, royalty-free license to use, display,
              reproduce, and distribute your content on the platform.
            </li>
          </ul>
          <p>Users are prohibited from sharing:</p>
          <ul>
            <li>Copyrighted or offensive material.</li>
            <li>Content promoting illegal activities.</li>
          </ul>
          <h3>5.2 Ownership of Platform Content</h3>
          <p>
            All content provided by the platform, including AI-generated
            analytics, event recordings, and intellectual property, remains the
            sole property of Kombat+. Users may not reproduce or
            distribute such content without prior written consent.
          </p>

          <h2>6. Privacy and Data Protection</h2>
          <p>
            We adhere to the highest standards of data privacy, including GDPR,
            CCPA, and other international frameworks.
          </p>
          <h3>6.1 Personal Data Collection</h3>
          <p>We collect:</p>
          <ul>
            <li>
              <b>Personal Data:</b> Names, email addresses, billing information.
            </li>
            <li>
              <b>Usage Data:</b> IP addresses, device details, and activity
              logs.
            </li>
            <li>
              <b>Blockchain Data:</b> Wallet addresses and transaction
              histories.
            </li>
          </ul>
          <h3>6.2 User Rights</h3>
          <b>Under GDPR</b>
          <ul>
            <li>
              Right to access, correct, delete, or restrict processing of your
              data.
            </li>
            <li>Right to data portability.</li>
            <li>
              Right to lodge complaints with your local data protection
              authority.
            </li>
          </ul>
          <b>Under CCPA</b>
          <ul>
            <li>Right to know what personal data is collected.</li>
            <li>Right to request deletion of data.</li>
            <li>Right to opt-out of the sale of your personal data.</li>
          </ul>
          <h3>6.3 Security Measures</h3>
          <p>
            We use encryption, multi-factor authentication, and other security
            protocols to protect user data. However, we cannot guarantee full
            security, especially for publicly visible blockchain data.
          </p>
          <h2>7. Acceptable Use Policy</h2>
          <p>You agree not to:</p>
          <ul>
            <li>
              Engage in fraudulent or deceptive activities, including token
              manipulation.
            </li>
            <li>Attempt to hack or disrupt platform functionalities.</li>
            <li>
              Use the platform to distribute malware, spam, or illegal content.
            </li>
          </ul>

          <h2>8. Limitation of Liability</h2>
          <ul>
            <li>
              <b>General:</b> The platform is provided "as is" without
              warranties.
            </li>
            <li>
              <b>Blockchain Risks:</b> We are not liable for token loss, wallet
              errors, or blockchain malfunctions.
            </li>
            <li>
              <b>Service Interruptions:</b> We are not responsible for losses
              arising from downtime, maintenance, or technical failures.
            </li>
          </ul>

          <h2>9. Dispute Resolution</h2>
          <h3>9.1 Governing Law</h3>
          <p>
            This Agreement is governed by the laws of [Insert Jurisdiction].
          </p>
          <h3>9.2 Arbitration</h3>
          <p>
            All disputes must be resolved through binding arbitration under
            [rules of arbitration body]. Costs and outcomes are final unless
            otherwise required by law.
          </p>
          <h3>9.3 Class Action Waiver</h3>
          <p>
            You waive the right to participate in class actions or similar
            collective proceedings.
          </p>

          <h2>10. Updates to Terms</h2>
          <p>
            These Terms may be updated periodically. Material changes will be
            communicated via email or platform notifications. Continued use
            after updates constitutes acceptance.
          </p>

          <h2>11. Contact Us</h2>
          <p>For questions, feedback, or support, please contact us at:</p>
          <p>
            Email:
            <br></br>
            Phone:
          </p>
        </div>
      </div>
      <Footer />
    </ThemeContextProvider>
  );
};

export default TermsOfService;
