import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./navbar.scss";
import logo from "../../assets/logo.png";
import { Link, useLocation } from "react-router-dom";

const NavbarSecion = () => {
  const [scrolled, setScrolled] = useState(false);
  const [show, setShow] = useState(false);
  const currentLocation = useLocation()
  const isActiveLink = (path) => currentLocation.pathname === path;

  const handleToggle = () => setShow(!show);
  const closeOffcanvas = () => setShow(false);


  useEffect(() => {
    // Check if window is defined before adding the event listener
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 100) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  return (
    <>
      {["lg"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className={scrolled ? "nav-scrolled mb-3" : "bg-body-tertiary mb-3"}
          bg="dark"
          data-bs-theme="dark"
          fixed="top"
        >
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} alt="Logo"></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={handleToggle} />
            <Navbar.Offcanvas
              show={show}
              onHide={closeOffcanvas}
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              bg="dark"
              data-bs-theme="dark"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img src={logo} alt="Logo"></img>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1">
                  <Nav.Link as={Link} to="/" className={isActiveLink("/") ? "navSeleted" : ""} onClick={closeOffcanvas} >Home </Nav.Link>
                  <Nav.Link as={Link} to="/fighting-promotions" className={isActiveLink("/fighting-promotions") ? "navSeleted" : ""} onClick={closeOffcanvas} >FIGHTING PROMOTIONS</Nav.Link>
                  <Nav.Link as={Link} to="/become-an-affiliate" className={isActiveLink("/become-an-affiliate") ? "navSeleted" : ""} onClick={closeOffcanvas}>BECOME AN AFFILIATE</Nav.Link>
                  <Nav.Link as={Link} to="/about-us" className={isActiveLink("/about-us") ? "navSeleted" : ""} onClick={closeOffcanvas} >ABOUT US</Nav.Link>
                  <Nav.Link as={Link} to="/contact-us" className={isActiveLink("/contact-us") ? "navSeleted" : ""} onClick={closeOffcanvas} >Contact US</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default NavbarSecion;
