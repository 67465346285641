import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function SnackbarUI({ open, content, severity, setOpenSnack }) {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    return (
        <Box sx={{ width: 500 }}>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                onEntered={()=> (open = false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleClose}
                    severity={severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {content}
                </Alert>
            </Snackbar>
        </Box>
    );
}
