import React, { useCallback, useEffect, useState } from "react";
import { ThemeContextProvider } from "../../themecontext";
import Footer from "../footer/footer";
import "./ContactUs.scss";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Button from "react-bootstrap/Button";
import axios from 'axios'
import { apiEndPoint, headers } from "../../utils/constants";
import { MuiTelInput } from "mui-tel-input";
import SnackbarUI from '../common/snackbar';
import { Helmet } from 'react-helmet-async';

const initialState = {
  name: "",
  email: "",
  phoneNumber: "",
  message:""
};

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [formSubmitted,setFormSubmitted] = useState(false)
  const [open, setOpenSnack] = useState(false);
  const [content, setContent] = useState('Form Submitted Successfully');
  const [severity, setSeverity] = useState('success')

  const handleForm = useCallback((event) => {
    console.log(event, "eventeventv");

    if (event.target) {
      const { name, value, type, checked } = event.target;
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } else if (event) {
      setFormData((prev) => ({
        ...prev,
        phoneNumber: event
      }))
      setErrors((prev) => ({ ...prev, phoneNumber: "" }));
    }
  }, []);

  const validateFormData = (data) => {
    let errors = {};
    if (data.name == "") {
      errors.name = "Required";
    }
    if (data.email == "") {
      errors.email = "Required";
    }
    if (data.phoneNumber == "") {
      errors.phoneNumber = "Required"
    }
    return errors
  };

  const saveDraftForm = (e) => {
    e.preventDefault();
    console.log(formData,"formDataformData");
    
    const validateErrors = validateFormData(formData);
    setErrors(validateErrors)
    if(Object.keys(validateErrors).length === 0){
      axios.post(`${apiEndPoint}/contact-request`, formData, headers)
        .then((response) => {
          if (response.status == 201) {
            setFormSubmitted(true)
            setOpenSnack(true)
          }
        })
        .catch((e) => {
          setOpenSnack(true)
          setContent("Something Went wrong !")
          setSeverity("error")
      })
    }
  };
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
    message:""
    });
    setErrors({});
    setFormSubmitted(false);
  };

  return (
    <>
     <Helmet>
      <title>KombatPlus - Revolutionizing Combat Sports with Interactive Streaming, NFTs, and Fan Rewards</title>
      <meta property="og:title" content="KombatPlus - Revolutionizing Combat Sports with Interactive Streaming, NFTs, and Fan Rewards" />
      <meta name="description" content="KombatPlus transforms combat sports with live streaming, interactive fan features, exclusive content, and a rewards program. Engage with MMA, boxing, and wrestling events like never before with personalized recommendations, real-time polls, NFTs, and more. Join the future of combat sports entertainment and connect with fans worldwide." />
      <meta name="keywords" content="combat sports streaming, interactive fan experience, MMA live events, boxing, wrestling PPV, combat sports NFTs, fan rewards, personalized content, AI recommendations, event ticketing, live polls, fan engagement, Web3 sports platform, KombatPlus community, exclusive fighter content, combat sports subscriptions, affiliate program, KombatPlus rewards." />
    </Helmet>
    <ThemeContextProvider>
      <div className="contactus">
        <div className="container">
          <div className="row justify-content-between align-items-center gx-5">
            <div className="col-md-7">
              <h1>Contact Us</h1>
              <p>
                We’d love to hear from you! Whether you have questions,
                feedback, or need assistance, please reach out. Our team is here
                to help and will get back to you as soon as possible.
              </p>
            </div>
            <div className="col-md-4">
              <div className="contact-box h-100 p-3">
                {/* Form */}
                {!formSubmitted ? <form>
                                        <div className="pb-3">
                                            <FormControl className="w-100" variant="outlined">
                                                <OutlinedInput
                                                    id="outlined-adornment-weight"
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        "aria-label": "weight",
                                                    }}
                                                    name="name"
                                                    placeholder="Name*"
                                                    value={formData.name}
                                                    onChange={handleForm}
                                                />
                                            </FormControl>

                                            {errors && errors.name && (
                                                <p className="error mb-0 text-start">{errors.name}</p>
                                            )}
                                        </div>
                                        <div className="pb-3">
                                            <FormControl className="w-100" variant="outlined">
                                                <OutlinedInput
                                                    id="outlined-adornment-weight"
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        "aria-label": "weight",
                                                    }}
                                                    name="email"
                                                    placeholder="Email*"
                                                    value={formData.email}
                                                    onChange={handleForm}
                                                />
                                            </FormControl>
                                            {errors && errors.email && (
                                                <p className="error mb-0 text-start">{errors.email}</p>
                                            )}
                                        </div>
                                        {/* <div className="pb-3">
                                            <FormControl className="w-100" variant="outlined">
                                                <OutlinedInput
                                                    id="outlined-adornment-weight"
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        "aria-label": "weight",
                                                        inputMode: "numeric",
                                                        pattern: "[0-9]*",
                                                    }}
                                                    name="phoneNumber"
                                                    placeholder="Phone Number*"
                                                    value={formData.phoneNumber}
                                                    onChange={handleForm}
                                                />
                                            </FormControl>
                                            {errors && errors.phoneNumber && (
                                                <p className="error mb-0 text-start">{errors.phoneNumber}</p>
                                            )}
                                        </div> */}
                                        <div className="pb-3">
                                            <FormControl className="w-100" variant="outlined">
                                                <MuiTelInput
                                                    defaultCountry="US"
                                                    value={formData.phoneNumber}
                                                    onChange={handleForm}
                                                    placeholder="Phone Number*"
                                                    name="phoneNumber"
                                                    error={!!errors.phoneNumber} // Show error outline if there's an error
                                                />
                                            </FormControl>
                                            {errors && errors.phoneNumber && (
                                                <p className="error mb-0 text-start">{errors.phoneNumber}</p>
                                            )}
                                        </div>
                                        <div className="pb-3">
                                            <TextField
                                                id="outlined-multiline-static"
                                                multiline
                                                rows={4}
                                                defaultValue="message"
                                                className="w-100"
                                                name="message"
                                                placeholder='Message'
                                                value={formData.message}
                                                onChange={handleForm}
                                            />
                                            {errors && errors.message && (
                                                <p className="error mb-0 text-start">{errors.message}</p>
                                            )}
                                        </div>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="w-100 rounded-pill"
                                            onClick={saveDraftForm}
                                        >
                                            Submit
                                        </Button>
                                    </form> : (
                                         <div className="thank-you-message h-100 text-center d-flex align-items-center justify-content-center">
                                            <div>
                                              <h2 className="text-white">Thank You for Submitting the Form!</h2>
                                              <p className="text-white">We appreciate your interest and will be in touch soon.</p>
                                              <Button
                                                  variant="primary"
                                                  className="mt-3 text-white px-5"
                                                  onClick={resetForm}
                                              >
                                                  Ok
                                              </Button>
                                            </div>
                                        </div>
                                    )}
                </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </ThemeContextProvider>
    <SnackbarUI open={open} setOpenSnack={setOpenSnack} content={content} severity={severity} />
    </>
  );
};

export default ContactUs;
