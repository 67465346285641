import React, { useState, useEffect } from "react";
import { ThemeContextProvider } from "../../themecontext";
import Button from "react-bootstrap/Button";
import Footer from "../footer/footer";
import "./home.scss";
import logo from "../../assets/logo.png";
import homeIcon1 from "../../assets/home-icon1.svg";
import homeIcon2 from "../../assets/home-icon2.svg";
import homeIcon3 from "../../assets/home-icon3.svg";
import homeImg1 from "../../assets/home-img1.png";
import homeImg2 from "../../assets/home-img2.png";
import homeImg3 from "../../assets/home-img3.png";
import homeImg4 from "../../assets/home-img4.png";
import homeImg5 from "../../assets/home-img5.png";
import homeImg6 from "../../assets/home-img6.png";
import homeImg7 from "../../assets/home-img7.png";
import rewardsIcon1 from "../../assets/rewards-icon1.svg";
import rewardsIcon2 from "../../assets/rewards-icon2.svg";
import rewardsIcon3 from "../../assets/rewards-icon3.svg";
import { Helmet } from 'react-helmet-async';
import boxingNetwork from "../../assets/boxing-network.png";
import jitsuNetwork from "../../assets/jitsu-network.png";
import kickboxingNetwork from "../../assets/kickboxing-network.png";
import mmaNetwork from "../../assets/mma-network.png";
import wrestlingNetwork from "../../assets/wrestling-network.png";
import { v4 as uuidv4 } from "uuid";
import Card from "../carousel_related/Card";
import CarouselDisplay from "../carousel_related/CarousalDisplay";
import SignupForm from "../signup_form/SignupForm";

const Home = () => {
  function handleNavigation(url) {
    window.open(url, "_blank");
  }

  const [timeLeft, setTimeLeft] = useState({});

  const targetDate = new Date("2025-01-15T00:00:00");

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
          2,
          "0"
        ),
        hours: String(
          Math.floor((difference / (1000 * 60 * 60)) % 24)
        ).padStart(2, "0"),
        minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(
          2,
          "0"
        ),
        seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    setTimeLeft(calculateTimeLeft());
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={boxingNetwork}
          title="Boxing Network"
          description="From heavyweight showdowns to rising stars, get the best of boxing on demand."
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={jitsuNetwork}
          title="Jiu-Jitsu Network"
          description="Follow the top practitioners in the sport, with expert analysis and live competitions."
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={kickboxingNetwork}
          title="Kickboxing Network"
          description="Fast-paced, hard-hitting content from the world of kickboxing, delivered in real-time."
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={mmaNetwork}
          title="MMA Network"
          description="Non-stop action with live events, fighter profiles, and exclusive content."
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={wrestlingNetwork}
          title="Wrestling Network"
          description="Dive into the world of wrestling with live matches, interviews, and behind-the-scenes footage."
        />
      ),
    },
  ];

  return (<>
    <Helmet>
      <title>KombatPlus - Revolutionizing Combat Sports with Interactive Streaming, NFTs, and Fan Rewards</title>
      <meta property="og:title" content="KombatPlus - Revolutionizing Combat Sports with Interactive Streaming, NFTs, and Fan Rewards" />
      <meta name="description" content="KombatPlus transforms combat sports with live streaming, interactive fan features, exclusive content, and a rewards program. Engage with MMA, boxing, and wrestling events like never before with personalized recommendations, real-time polls, NFTs, and more. Join the future of combat sports entertainment and connect with fans worldwide." />
      <meta name="keywords" content="combat sports streaming, interactive fan experience, MMA live events, boxing, wrestling PPV, combat sports NFTs, fan rewards, personalized content, AI recommendations, event ticketing, live polls, fan engagement, Web3 sports platform, KombatPlus community, exclusive fighter content, combat sports subscriptions, affiliate program, KombatPlus rewards." />
    </Helmet>
    <ThemeContextProvider>
      <div className="home-hero d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-10 col-md-7">
              <div className="pb-3">
                <div className="red-line"></div>
              </div>
              <h1>Unleash the Revolutionary Fan Experience with</h1>
              <div className="pb-3">
                <img src={logo} className="logo"></img>
              </div>
              <p>
                Unlock live events, exclusive behind-the-scenes content, and
                fan-driven community experiences that put you right in the
                action.
                <span className="logo-text px-2">
                  <span>KO</span>MBAT<span className="plus">+</span>
                </span>
                offers 24/7 access to top fighters and the raw energy of combat
                sports from around the world. Feel the thrill like never before!
              </p>
              <div>
                <Button
                  variant="primary"
                  className="me-3"
                  onClick={() =>
                    handleNavigation(
                      "https://stageplatform.kombatplus.com/signup"
                    )
                  }
                >
                  Join KOMBAT+ Today
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() =>
                    handleNavigation(
                      "https://stageplatform.kombatplus.com/network-market/event"
                    )
                  }
                >
                  See Upcoming Events
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="launching">
        <div className="container">
          <div className="box-bg">
            <div className="row align-items-center">
              <div className="col">
                <div className="title">LAUNCHING IN</div>
              </div>
              <div className="col d-flex justify-content-center justify-content-md-center">
                <div className="time-box">
                  {/* <div className="count">01</div> */}
                  <div className="count">{timeLeft.days}</div>
                  <div className="days">Days</div>
                </div>
                <div className="time-box">
                  {/* <div className="count">01</div> */}
                  <div className="count">{timeLeft.hours}</div>
                  <div className="days">Hours</div>
                </div>
                <div className="time-box">
                  {/* <div className="count">01</div> */}
                  <div className="count">{timeLeft.minutes}</div>
                  <div className="days">Minutes</div>
                </div>
                <div className="time-box">
                  {/* <div className="count">01</div> */}
                  <div className="count">{timeLeft.seconds}</div>
                  <div className="days">Seconds</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section2">
        <div className="container">
          <div className="row justify-content-center pb-3 pb-md-5">
            <div className="col-5">
              <div className="red-line"></div>
            </div>
          </div>
          <div className="row justify-content-center pb-3">
            <div className="col-md-8 text-center">
              <h2 className="pb-3">An Experience Like Never Before</h2>
              <div className="text1">
                <span className="logo-text pe-2">
                  <span>KO</span>MBAT<span className="plus">+</span>
                </span>
                isn’t just about watching; it’s about feeling like you’re
                ringside, wherever you are.
              </div>
            </div>
          </div>
          <div className="row text-center icon-section py-5">
            <div className="col">
              <img src={homeIcon1} className="pb-4"></img>
              <h5 className="fw-bold">Immersive Live Experiences</h5>
            </div>
            <div className="col">
              <img src={homeIcon2} className="pb-4"></img>
              <h5 className="fw-bold">Interactive Community Features</h5>
            </div>
            <div className="col">
              <img src={homeIcon3} className="pb-4"></img>
              <h5 className="fw-bold">Exclusive Fan Content</h5>
            </div>
          </div>
          <div className="details-section py-2 py-md-5">
            <div className="row gx-md-5">
              <div className="col-md-5 pb-3 pb-md-0">
                <img
                  src={homeImg1}
                  className="mw-100"
                  alt="Interact with Fighters, Fellow Fans, and Even the Fight Itself"
                ></img>
              </div>
              <div className="col-md-7">
                <div className="title">
                  Interact with Fighters, Fellow Fans, and Even the Fight Itself
                </div>
                <p>
                  Our unique interactive tools make every event unforgettable.
                </p>
                <div className="title2">Live Chat & Reactions</div>
                <p className="p2">
                  Join the live chat, celebrate knockouts, and connect with fans
                  globally.
                </p>
                <div className="title2">Real-Time Polls</div>
                <p className="p2">
                  Vote on fight outcomes, fighter stats, and more – your voice
                  matters.
                </p>
                <div className="title2">Q&A and Meet & Greet</div>
                <p className="p2">
                  Ask fighters questions live and get exclusive
                  behind-the-scenes access.
                </p>
              </div>
            </div>
          </div>
          <div className="details-section py-2 py-md-5">
            <div className="row gx-md-5">
              <div className="col-md-7 order-1 order-md-0">
                <div className="title">
                  Get Closer to the Fighters and Events You Love
                </div>
                <p>
                  Unlock content you won’t find anywhere else – only with
                  <span className="logo-text ps-2">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                </p>
                <div className="title2">
                  Fighter Profiles & Training Insights
                </div>
                <p className="p2">
                  Own memorable fight moments, fighter badges, and exclusive
                  content.
                </p>
                <div className="title2">
                  Event Previews & Post-Event Analysis
                </div>
                <p className="p2">
                  Limited-edition releases for superfans – claim yours before
                  it’s gone.
                </p>
                <div className="title2">Pay-Per-View & Subscriptions</div>
                <p className="p2">
                  Choose from PPV or monthly subscriptions to catch every move.
                </p>
              </div>
              <div className="col-md-5 order-0 order-md-1 pb-3 pb-md-0">
                <img
                  src={homeImg2}
                  className="mw-100"
                  alt="Get Closer to the Fighters and Events You Love"
                ></img>
              </div>
            </div>
          </div>
          <div className="details-section py-2 py-md-5">
            <div className="row gx-md-5">
              <div className="col-md-5 pb-3 pb-md-0">
                <img
                  src={homeImg3}
                  className="mw-100"
                  alt="We use AI to bring you the fighters, fights, and content that you love most."
                ></img>
              </div>
              <div className="col-md-7">
                <div className="title">
                  Your
                  <span className="logo-text px-2">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                  – Tailored to You
                </div>
                <p>
                  We use AI to bring you the fighters, fights, and content that
                  you love most.
                </p>
                <div className="title2">Tailored Event Suggestions</div>
                <p className="p2">
                  Get notifications for events based on your favorite leagues
                  and fighters.
                </p>
                <div className="title2">Fighter Stats & Recommendations</div>
                <p className="p2">
                  Track fighters, dive into stats, and get custom
                  recommendations.
                </p>
                <div className="title2">Personalized Highlights</div>
                <p className="p2">
                  Watch highlight reels and recaps featuring your favorite
                  moments and athletes.
                </p>
              </div>
            </div>
          </div>
          <div className="rewards text-center my-5">
            <h3>Earn Rewards, Access Special Perks, and More</h3>
            <p className="pb-3">
              Every moment you engage brings you closer to rewards – it’s our
              way of saying thanks.
            </p>
            <div className="row justify-content-center">
              <div className="col-md-3">
                <img src={rewardsIcon1} className="pb-2" alt="Tokens"></img>
                <h5>
                  <span className="logo-text pe-2">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                  Tokens
                </h5>
                <p>Earn tokens through engagement, purchases, and sharing.</p>
              </div>
              <div className="col-md-3">
                <img
                  src={rewardsIcon2}
                  className="pb-2"
                  alt="Redeemable Perks"
                ></img>
                <h5>Redeemable Perks</h5>
                <p>Use tokens to unlock event tickets and exclusive content.</p>
              </div>
              <div className="col-md-3">
                <img
                  src={rewardsIcon3}
                  className="pb-2"
                  alt="Badges & Achievements"
                ></img>
                <h5>Badges & Achievements</h5>
                <p>
                  Show off your dedication and earn unique fan badges for your
                  profile.
                </p>
              </div>
            </div>
          </div>
          <div className="details-section py-2 py-md-5">
            <div className="row gx-md-5">
              <div className="col-md-7 order-1 order-md-0">
                <div className="title">
                  Unlock More with Affiliate Promotions
                </div>
                <p>
                  Our affiliate program means more events, exclusive offers, and
                  fan growth across your favorite promotions.
                </p>
                <div className="title2">Referral Bonuses</div>
                <p className="p2">
                  Earn tokens through engagement, purchases, and sharing.
                </p>
                <div className="title2">Exclusive Content & Discounts</div>
                <p className="p2">
                  Affiliates bring you bonus content, special deals, and
                  giveaways.
                </p>
                <div className="title2">
                  Track Fan Engagement & Achievements
                </div>
                <p className="p2">
                  Affiliates reward you for sharing, watching, and engaging more
                  on
                  <span className="logo-text ps-1">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                </p>
              </div>
              <div className="col-md-5 order-0 order-md-1 pb-3 pb-md-0">
                <img
                  src={homeImg4}
                  className="mw-100"
                  alt="Unlock More with Affiliate Promotions"
                ></img>
              </div>
            </div>
          </div>
          <div className="details-section py-2 py-md-5">
            <div className="row gx-md-5">
              <div className="col-md-5 pb-3 pb-md-0">
                <img
                  src={homeImg5}
                  className="mw-100"
                  alt="What’s Next on"
                ></img>
              </div>
              <div className="col-md-7">
                <div className="title">
                  What’s Next on
                  <span className="logo-text ps-2">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                </div>
                <p>
                  See what’s coming up and never miss a moment of the action.
                </p>
                <div className="title2">Event Countdown</div>
                <p className="p2">
                  Get hyped with live countdowns for upcoming events.
                </p>
                <div className="title2">Fan Favorite Fights</div>
                <p className="p2">
                  Re-watch the fights the
                  <span className="logo-text px-1">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                  community loves.
                </p>
                <div className="title2">Notifications & Reminders</div>
                <p className="p2">
                  Get alerts for new fights and events based on your
                  preferences.
                </p>
              </div>
            </div>
          </div>
          <div className="one-platform py-5">
            <h3 className="text-center">
              One Platform, Multiple Networks, Endless Content.
            </h3>
            <p className="pb-5 text-center">
              <span className="logo-text pe-2">
                <span>KO</span>MBAT<span className="plus">+</span>
              </span>
              brings you the best of combat sports across multiple networks:
            </p>

            <div className="carousal_transition">
              {/* Carousel comes here */}
              <CarouselDisplay
                cards={cards}
                width="100%"
                margin="0 auto"
                offset={200}
                showArrows={false}
              />
            </div>
          </div>
          <div className="details-section py-2 py-md-5">
            <div className="row gx-md-5">
              <div className="col-md-7 order-1 order-md-0">
                <div className="title">Connect with Fans Around the World</div>
                <p>
                  Join a global community of fans who share your passion for
                  combat sports.
                </p>
                <div className="title2">Discussion Groups & Forums</div>
                <p className="p2">
                  Connect with fans, share predictions, and relive iconic
                  fights.
                </p>
                <div className="title2">Exclusive Social Media Groups</div>
                <p className="p2">
                  Join private
                  <span className="logo-text px-1">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                  groups for die-hard fans.
                </p>
                <div className="title2">Fan Recognition</div>
                <p className="p2">
                  <span className="logo-text pe-1">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                  highlights fans through engagement rewards and spotlight
                  features.
                </p>
              </div>
              <div className="col-md-5 order-0 order-md-1 pb-3 pb-md-0">
                <img
                  src={homeImg6}
                  className="mw-100"
                  alt="Connect with Fans Around the World"
                ></img>
              </div>
            </div>
          </div>
          <div className="details-section py-2 py-md-5">
            <div className="row gx-md-5">
              <div className="col-md-5 pb-3 pb-md-0">
                <img
                  src={homeImg7}
                  className="mw-100"
                  alt="Own a Piece of the Action"
                ></img>
              </div>
              <div className="col-md-7">
                <div className="title">Own a Piece of the Action </div>
                <p>
                  Bring the fights to your digital collection with unique NFTs
                  and limited-edition memorabilia.
                </p>
                <div className="title2">NFT Collectibles</div>
                <p className="p2">
                  Own memorable fight moments, fighter badges, and exclusive
                  content.
                </p>
                <div className="title2">Exclusive Drops</div>
                <p className="p2">
                  Limited-edition releases for superfans – claim yours before
                  it’s gone.
                </p>
                <div className="title2">Wallet Integration</div>
                <p className="p2">
                  Store and showcase your collectibles on your  <span className="logo-text px-1">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                  profile.
                </p>
              </div>
            </div>
          </div>
          <div className="become-part my-5 d-flex justify-content-center align-items-center text-center">
            <div>
              <h3>
                Become Part of the
                <span className="logo-text px-2">
                  <span>KO</span>MBAT<span className="plus">+</span>
                </span>
                Family
              </h3>
              <p className="pb-4">
                Join today and get closer to the fighters and events you love.
              </p>
              <Button
                variant="primary"
                className="px-4"
                onClick={() =>
                  handleNavigation(
                    "https://stageplatform.kombatplus.com/signup"
                  )
                }
              >
                Sign Up Now
              </Button>
            </div>
          </div>
        </div>
          <SignupForm />
        <Footer />
      </div>
    </ThemeContextProvider>
    </>
  );
};

export default Home;
