import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { ThemeContextProvider } from "../../themecontext";
import SnackbarUI from '../common/snackbar';
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import axios from 'axios'
import { apiEndPoint, headers } from "../../utils/constants";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { MuiTelInput } from "mui-tel-input";

const initialState = {
    name: "",
    email: "",
    phoneNumber: "",
    organizationName:"",
    message:""
};

const ScheduleDemoModal=({openModal,handleClose})=>{

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw',
        // bgcolor: 'black',
        borderRadius: '20px',
        backgroundColor:'#190909',
        boxShadow: 24,
        border: '2px solid #d31b11',
        p: 4,
      };

    const [open, setOpenSnack] = useState(false);
    const [content, setContent] = useState('Form Submitted Successfully');
    const [severity, setSeverity] = useState('success')

    const [formData, setFormData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [formSubmitted,setFormSubmitted] = useState(false)


  const handleForm = useCallback((event) => {

    if (event.target) {
      const { name, value, type, checked } = event.target;
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } else if (event) {
      setFormData((prev) => ({
        ...prev,
        phoneNumber: event
      }))
      setErrors((prev) => ({ ...prev, phoneNumber: "" }));
    }
  }, []);

//   const handleClose = () => setOpenModal(false);

  const saveForm = (e) => {
    e.preventDefault();
    const validateErrors = validateFormData(formData);
  };

  const validateFormData = (data) => {
    let errors = {};
    if (data.name == "") {
      errors.name = "Required";
    }
    if (data.email == "") {
      errors.email = "Required";
    }
    if (data.phoneNumber == "") {
      errors.phoneNumber = "Required"
    }
    return errors
  };

  const saveDraftForm = (e) => {
    e.preventDefault();
    console.log(formData,"formDataformData");
    
    const validateErrors = validateFormData(formData);
    setErrors(validateErrors)
    if(Object.keys(validateErrors).length === 0){
      axios.post(`${apiEndPoint}/request-demo`, formData, headers)
        .then((response) => {
          if (response.status == 201) {
            setFormSubmitted(true)
            setOpenSnack(true)
          }
        })
        .catch((e) => {
          setOpenSnack(true)
          setContent("Something Went wrong !")
          setSeverity("error")
        })
    }
  };
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      organizationName:"",
    message:""
    });
    setErrors({});
    setFormSubmitted(false);
  };

    return(
        <>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
          <CloseIcon onClick={handleClose} className="modal-close"/>
          <ThemeContextProvider>
              <div className="draft-application-form">
                <div className="container">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-md-6">
                      <h1 className="modal-form-heading">Schedule A Demo</h1>
                      {/* <div className="row">
                        <div className="col-md-10">
                          <p>
                            We are actively signing MMA athletes, making them eligible for the GFL MMA Draft on January 15, 2025. If you’re ready to be part of this revolutionary opportunity, submit your information and let us know of your interest.
                          </p>
                        </div>
                      </div> */}
                    </div>
                    <div className="col-md-4">
                      <div className="contact-box p-4">
                        {!formSubmitted ? <form>
                          <div className="pb-3">
                            <FormControl className="w-100" variant="outlined">
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                name="name"
                                placeholder="Name*"
                                value={formData.name}
                                onChange={handleForm}
                              />
                            </FormControl>

                            {errors && errors.name && (
                              <p className="error mb-0 text-start">{errors.name}</p>
                            )}
                          </div>
                          <div className="pb-3">
                            <FormControl className="w-100" variant="outlined">
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                name="email"
                                placeholder="Email*"
                                value={formData.email}
                                onChange={handleForm}
                              />
                            </FormControl>
                            {errors && errors.email && (
                              <p className="error mb-0 text-start">{errors.email}</p>
                            )}
                          </div>
                          {/* <div className="pb-3">
                            <FormControl className="w-100" variant="outlined">
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                }}
                                name="phoneNumber"
                                placeholder="Phone Number*"
                                value={formData.phoneNumber}
                                onChange={handleForm}
                              />
                            </FormControl>
                            {errors && errors.phoneNumber && (
                              <p className="error mb-0 text-start">{errors.phoneNumber}</p>
                            )}
                          </div> */}
                          <div className="pb-3">
                        <FormControl className="w-100" variant="outlined">
                          <MuiTelInput
                            defaultCountry="US"
                            value={formData.phoneNumber}
                            onChange={handleForm}
                            placeholder="Phone Number*"
                            name="phoneNumber"
                            error={!!errors.phoneNumber} // Show error outline if there's an error
                          />
                        </FormControl>
                        {errors && errors.phoneNumber && (
                          <p className="error mb-0 text-start">{errors.phoneNumber}</p>
                        )}
                      </div>
                      <div className="pb-3">
                            <FormControl className="w-100" variant="outlined">
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                name="organizationName"
                                placeholder="Organization Name"
                                value={formData.organizationName}
                                onChange={handleForm}
                              />
                            </FormControl>
                            {/* {errors && errors.email && (
                              <p className="error mb-0 text-start">{errors.email}</p>
                            )} */}
                          </div>
                          <div className="pb-3">
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      defaultValue="Message"
                      className="w-100"
                      name="message"
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleForm}
                    />
                  </div>
                          <Button
                            variant="primary"
                            type="submit"
                            className="w-100 rounded-pill"
                            onClick={saveDraftForm}
                          >
                            Submit
                          </Button>
                        </form> : (
                          <div className="thank-you-message text-center">
                            <h2 className="text-white">Thank You for Submitting the Form!</h2>
                            <p className="text-white">We appreciate your interest and will be in touch soon.</p>
                            <Button
                              variant="primary"
                              className="mt-3 text-white"
                              onClick={resetForm}
                            >
                              Ok
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ThemeContextProvider>
            <SnackbarUI open={open} setOpenSnack={setOpenSnack} content={content} severity={severity} />
          </Box>
        </Fade>
      </Modal>
        </>
    )
}
export default ScheduleDemoModal;