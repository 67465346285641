import Carousel from "react-spring-3d-carousel";
import { useState, useEffect, useRef } from "react";
import { config } from "react-spring";

function CarouselDisplay(props) {
    const table = props.cards.map((element, index) => {
        return { ...element, onClick: () => setGoToSlide(index) };
    });

    const [offsetRadius, setOffsetRadius] = useState(4);
    //   const [offsetRadius, setOffsetRadius] = useState(2);//ch
    const [showArrows, setShowArrows] = useState(false);
    const [goToSlide, setGoToSlide] = useState(0);
    const [cards] = useState(table);

    // Ref to control the autoplay interval
      const autoplayRef = useRef(null);

    // Automatically move the carousel
      useEffect(() => {
        autoplayRef.current = setInterval(() => {
          setGoToSlide((prev) => (prev + 1) % cards.length); // Move to the next slide
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(autoplayRef.current); // Cleanup on unmount
      }, [cards.length]);

    // //Reset autoplay on manual slide click
      useEffect(() => {
        clearInterval(autoplayRef.current); // Clear the interval
        autoplayRef.current = setInterval(() => {
          setGoToSlide((prev) => (prev + 1) % cards.length); // Restart autoplay
        }, 3000);
      }, [goToSlide]);

    // Update properties from props
    useEffect(() => {
        // setOffsetRadius(props.offset || 2);//ch
        setOffsetRadius(props.offset);
        setShowArrows(props.showArrows);
    }, [props.offset, props.showArrows]);

    return (
   
        <div
            style={{ width: props.width, height: props.height, margin: props.margin }}
        >
            <Carousel
                // offset={1}
                slides={cards}
                goToSlide={goToSlide}
                offsetRadius={offsetRadius}
                showNavigation={showArrows}
                animationConfig={config.gentle}
            />
        </div>
    );
}

export default CarouselDisplay;